<template>
<div class="dash-propterRem">
  <div>
    <div class="dpr-box">
      <div class="title">PROPTER REM</div>
      <div class="flex m-t">
        <div class="d-item">
          <div>Imóveis com débitos</div>
          <div class="destaq">{{ stats.resumoFinanceiro.imoveisComDebitos|number }}</div>
        </div>
        <div class="d-item">
          <div>Sem débitos</div>
          <div class="destaq text-positive">{{ (stats.resumoFinanceiro.totalDisponiveis - stats.resumoFinanceiro.imoveisComDebitos)|number }}</div>
        </div>
        <div>
          <div>Para análise</div>
          <div class="destaq text-orange">{{ stats.resumoFinanceiro.paraAnalise|number }}</div>
        </div>
      </div>

      <div class="m-t">
        <div class="title"><strong>Principais tipos de débitos</strong> pendentes</div>
        <div v-for="m in stats.resumoFinanceiro.principaisDebitos" class="m-t dpr-list">
          <div>{{ m.descricao }}</div>
          <div>{{ m.totalDebitos|number }} débitos</div>
          <div class="text-right font-bold">R$ {{ m.totalValor|moeda }}</div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="dpr-box">
      <div class="title">PAGAMENTOS AGENDADOS</div>
      <div class="flex m-t">
        <div class="d-item">
          <div><span v-if="filtros.data1 && filtros.data2">Período {{filtros.data1}} a {{filtros.data2}}</span><span v-else>Total</span></div>
          <div class="destaq min">R$ {{stats.resumoFinanceiro.debitosAgendadosPeriodo.totalValor|moeda}}</div>
        </div>
        <div class="d-item">
          <div>Total para hoje</div>
          <div class="destaq min">R$ {{stats.resumoFinanceiro.debitosAgendadosHoje.totalValor|moeda}}</div>
        </div>
      </div>

      <div class="m-t">
        <div class="title"><strong>Principais credores</strong></div>
        <div v-for="(credor, key) in stats.resumoFinanceiro.principaisCredores" :key="'credores-' + key" class="m-t dpr-list">
          <div>{{ credor.nome || 'Sem credor relacionado' }}</div>
          <div class="text-right font-bold">R$ {{ credor.totalValor|moeda }}</div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="dpr-box">
      <div class="title">PAGAMENTOS REALIZADOS</div>
      <div class="flex m-t">
        <div class="d-item">
          <div><span v-if="filtros.data1 && filtros.data2">Total para o período de {{filtros.data1}} a {{filtros.data2}}</span><span v-else>Total</span></div>
          <div class="destaq min text-positive">R$ {{stats.resumoFinanceiro.debitosPagosPeriodo.totalValor|moeda}}</div>
        </div>
      </div>
      <div class="flex m-t">
        <div class="d-item">
          <div>Total hoje</div>
          <div class="destaq min text-positive">R$ {{stats.resumoFinanceiro.debitosPagosHoje.totalValor|moeda}}</div>
        </div>
        <div class="d-item">
          <div>Quantidade de contas</div>
          <div class="destaq min text-positive">{{stats.resumoFinanceiro.debitosPagosHoje.totalDebitos|number}}</div>
        </div>
      </div>

<!--      <div class="m-t-lg flex justify-between">
        Filtrando <span v-if="filtros.data1 && filtros.data2">de {{filtros.data1}} a {{filtros.data2}}</span><span v-else>por todos períodos</span>
&lt;!&ndash;        <div class="col-grow-1 m-r-sm hide"><datetime-input date-type="date" date-format="##/##/####" @keydown.enter.native="filtroFinanceiro" field-view="tl" label="Data (De)" v-model="filtros.data1"/></div>
        <div class="col-grow-1 m-l-sm hide"><datetime-input date-type="date" date-format="##/##/####" @keydown.enter.native="filtroFinanceiro" field-view="tl" label="Data (Até)" v-model="filtros.data2"/></div>&ndash;&gt;
      </div>-->
    </div>
  </div>
</div>
</template>

<script>
import {format} from "date-fns"
// import DateInput from "@/reuse/input/Date"
import DatetimeInput from "@/reuse/input/Datetime"

export default {
  name: "DashAtivosFinanceiro",
  props: ['filtros', 'stats'],
  components: {
    // DatetimeInput,
    // DateInput
  },
  data () {
    return {}
  },
  methods: {
    filtroFinanceiro () {}
  }
}
</script>
