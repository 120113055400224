<template>
  <div id="chart-grafico1" class="chart-container"></div>
</template>

<script>
import Highcharts from 'highcharts'

export default {
  name: 'DashGrafico1',
  props: {
    dados: {required: true}
  },
  data() {
    return {
      chartData: null,
      categories: ['Dia 1', 'Dia 2', 'Dia 3', 'Dia 4', 'Dia 5', 'Dia 6','Dia 7', 'Dia 8', 'Dia 9', 'Dia 10'],
      series0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      series1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      series2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      series3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  computed: {
  },
  created() {
    this.load()
  },
  mounted() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: '.'
      }
    })
    this.createChart()
  },
  watch: {
    'dados.periodo.resultado' (v) {
      this.series1 = this.dados.periodo.resultado.robo
      this.series2 = this.dados.periodo.resultado.formulario
      this.series3 = this.dados.periodo.resultado.api
      this.categories = this.dados.periodo.intervaloDatas
      this.createChart()
    }
  },
  methods: {
    load() {
    },
    createChart() {
      Highcharts.chart('chart-grafico1', {
        chart: {
          type: 'areaspline',
          height: 300
        },
        title: {
          text: ''
        },
        /*
        subtitle: {
          align: 'center',
          text: 'Source: <a href="https://www.ssb.no/jord-skog-jakt-og-fiskeri/jakt" target="_blank">SSB</a>'
        },*/
        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 120,
          y: 70,
          floating: true,
          borderWidth: 1,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
        },
        xAxis: {
          categories: this.categories
        },
        yAxis: {
          title: {
            text: 'Quantidade de Cadastros'
          }
        },
        tooltip: {
          shared: true,
          headerFormat: '<b>Cadastros: {point.x}</b><br>'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            pointStart: 0
          },
          areaspline: {
            fillOpacity: 0.5
          }
        },
        series: [
          {
            name: 'Robô',
            color: '#2196f3',
            data: this.series1
          },
          {
            name: 'Formulário',
            color: '#27c24c',
            data: this.series2
          },
          {
            name: 'API',
            color: '#7266ba',
            data: this.series3
          }
        ]
      })
    }
  }
}
</script>
