<script>
/* eslint-disable */
import {ErpSField} from "uloc-vue-plugin-erp"
import ComitenteInput from "@/components/comitente/helpers/input/ComitenteInput"
import UProgress from "uloc-vue/src/components/progress/UProgress"
import SlCheckbox from "@/reuse/input/Checkbox"
import {date, UKnob} from "uloc-vue"
import DashGrafico1 from "@/components/dashboard/components/DashAtivos/Grafico1"
import DashAtivosFinanceiro from "@/components/dashboard/components/DashAtivos/Financeiro"
import {endOfMonth, format, startOfMonth} from "date-fns"
import DatetimeInput from "@/reuse/input/Datetime"
import {stats} from "@/domain/bem/services"
import bemWindow from "@/components/bem/windows/bem"
import {datePtToEn} from "@/utils/date"

export default {
  name: 'DashboardCadastro',
  provide: function () {
    return {
      dashboard: this
    }
  },
  mixins: [],
  components: {
    DatetimeInput,
    DashAtivosFinanceiro,
    DashGrafico1,
    SlCheckbox,
    ErpSField,
    ComitenteInput,
    UProgress,
    UKnob
  },
  data() {
    const end = endOfMonth(new Date());
    const data1 = format(startOfMonth(new Date()), 'dd/MM/yyyy')
    const data2 = format(end, 'dd/MM/yyyy')
    return {
      loading: false,
      filtros: {
        comitente: null,
        grafico1: true,
        data1: data1,
        data2: data2
      },
      colors: {
        resumoAtivos: ['info', 'negative', 'primary', 'purple', 'positive', 'dark'],
        resumoOperacional: ['info', 'orange', 'purple', 'negative', 'positive', 'dark']
      },
      fake: 20,
      stats: {
        origemCadastral: {
          total: 0,
          formulario: 0,
          robo: 0,
          api: 0,
          periodo: {
            resultado: null
          },
          disponibilidade: {
            ativos: {total: 0, avaliacao: 0},
            disponiveis: {total: 0, avaliacao: 0},
            indisponiveis: {total: 0, avaliacao: 0}
          }
        },
        resumoAtivos: [
          {
            title: 'Análise',
            color: 'info',
            total: 0,
            mercado: 0,
            avaliacao: 0,
            minimo: 0,
          },
          {
            title: 'Bloqueados',
            color: 'negative',
            total: 0,
            mercado: 0,
            avaliacao: 0,
            minimo: 0,
          },
          {
            title: 'Disponíveis',
            color: 'primary',
            total: 0,
            mercado: 0,
            avaliacao: 0,
            minimo: 0,
          },
          {
            title: 'Proposta',
            color: 'purple',
            total: 0,
            mercado: 0,
            avaliacao: 0,
            minimo: 0,
          },
          {
            title: 'Vendido',
            color: 'positive',
            total: 0,
            mercado: 0,
            avaliacao: 0,
            minimo: 0,
          },
          {
            title: 'Total',
            color: 'dark',
            total: 0,
            mercado: 0,
            avaliacao: 0,
            minimo: 0,
          }
        ],
        resumoOperacional: [
          {
            title: 'Disponíveis',
            color: 'info',
            total: 0
          },
          {
            title: 'Saneamento',
            color: 'orange',
            total: 0
          },
          {
            title: 'Providências',
            color: 'purple',
            total: 0
          },
          {
            title: 'Impedidos',
            color: 'negative',
            total: 0
          },
          {
            title: 'Venda',
            color: 'blue',
            total: 0
          },
          {
            title: 'Formalização',
            color: 'positive',
            total: 0
          },
          {
            title: 'Total',
            color: 'dark',
            total: 0
          }
        ],
        resumoFinanceiro: {
          imoveisComDebitos: 0,
          totalDisponiveis: 0,
          paraAnalise: 0,
          debitosAgendadosPeriodo: {
            totalDebitos: 0,
            totalValor: 0
          },
          debitosAgendadosHoje: {
            totalDebitos: 0,
            totalValor: 0
          },
          debitosPagosPeriodo: {
            totalDebitos: 0,
            totalValor: 0
          },
          debitosPagosHoje: {
            totalDebitos: 0,
            totalValor: 0
          },
          principaisCredores: []
        }
      }
    }
  },
  computed: {
    pctOrigemCadRobo () {
      const valor = ((this.stats.origemCadastral.robo / this.stats.origemCadastral.total) * 100).toFixed(2)
      if (!Number.isNaN(Number(valor))) {
        return this.formatNumber(valor)
      }
      return 0
    },
    pctOrigemCadForm () {
      const valor = ((this.stats.origemCadastral.formulario / this.stats.origemCadastral.total) * 100).toFixed(2)
      if (!Number.isNaN(Number(valor))) {
        return this.formatNumber(valor)
      }
      return 0
    },
    pctOrigemCadApi () {
      const valor = ((this.stats.origemCadastral.api / this.stats.origemCadastral.total) * 100).toFixed(2)
      if (!Number.isNaN(Number(valor))) {
        return this.formatNumber(valor)
      }
      return 0
    }
  },
  mounted() {
    this.load()
  },
  watch: {
    'filtros.comitente' () {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  beforeDestroy() {
  },
  methods: {
    load () {
      this.loading = true
      let data1, data2
      const filters = []
      if (this.filtros.data1) {
        data1 = datePtToEn(this.filtros.data1)
        data2 = datePtToEn(this.filtros.data2)
        if (!date.isValid(data1)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.push(`&data1=${data1}&data2=${data2 || ''}`)
      }
      stats(this.filtros.comitente, filters.join(''))
      .then(response => {
        this.loading = false
        this.stats = Object.assign(this.stats, response.data)
        console.log(response)
      }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
    filtroResumoAtivos () {
      this.load()
    },
    filtroResumoOperacional () {},
    formatNumber (n) {
      if (String(n).endsWith('.00')){
        return parseInt(Number(n))
      }
      return Number(n)
    },
    pct (vi, vf) {
      const valor = ((Number(vi) / Number(vf)) * 100).toFixed(2)
      if (!Number.isNaN(Number(valor))) {
        return this.formatNumber(valor)
      }
      return 0
    },
    bemWindow: bemWindow,
    gotoList (extraFilters = {}) {
      if (!this.filtros.grafico1) return
      const filtros = {
        comitente: this.filtros.comitente,
        tipoData: 'createdAt',
        data1: this.filtros.data1 ? this.filtros.data1 : null,
        data2: this.filtros.data2 ? this.filtros.data2 : null,
        ...extraFilters
      }
      const routeData = this.$router.resolve({name: 'bens.lista', query: filtros})
      window.open(routeData.href, '_blank')
    }
  },
  meta: {
    title: 'Dashboard Cadastro',
    name: 'Dashboard'
  }
}
</script>

<template>
  <div class="flex col-grow wrapper-md m-t-n">
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
  <div class="flex col-grow no-wrap dashboard-container dash-cad">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <div class="origem hbox">
          <div class="hcol wrapper-lg">
            <div class="flex justify-between">
              <div class="col-grow-1 font-16 text-grey-6">Origem dos cadastros <span v-if="filtros.data1 && filtros.data2">de {{filtros.data1}} a {{filtros.data2}}</span><span v-else>dos últimos 10 dias</span></div>
              <div class="col-grow-1 text-right justify-end flex"><sl-checkbox v-model="filtros.grafico1" /></div>
            </div>
            <div class="m-t">
              <dash-grafico1 :dados="stats.origemCadastral" v-show="filtros.grafico1" />
            </div>
          </div>
          <div class="hcol filter wrapper-lg">
            <div>
              <erp-s-field
                  view="tl"
                  label="Cliente:"
              >
                <div class="m-l-xs m-r-n-xs"><comitente-input size="2" v-model="filtros.comitente" /></div>
              </erp-s-field>
            </div>
            <div>
              <div class="m-t-sm flex justify-between" style="max-width: 240px">
                <div class="col-grow-1 m-r-xs"><datetime-input date-type="date" date-format="##/##/####" @keydown.enter.native="filtroResumoAtivos" field-view="tl" label="Data (De)" v-model.lazy="filtros.data1"/></div>
                <div class="col-grow-1 m-l-xs"><datetime-input date-type="date" date-format="##/##/####" @keydown.enter.native="filtroResumoAtivos" field-view="tl" label="Data (Até)" v-model.lazy="filtros.data2"/></div>
              </div>
            </div>
            <div class="m-t-lg">
              <div class="erp-s-field-label">Resumo Total da Origem</div>
              <div class="cursor-pointer m-t-md" @click="gotoList({origem: 1})">
                <div class="flex justify-between">
                  <div class="col-grow-1">Robô <small>{{stats.origemCadastral.robo|number}}</small></div>
                  <div class="flex-zero text-right text-blue">{{ pctOrigemCadRobo }}%</div>
                </div>
                <div class="bg-white">
                  <u-progress
                      class="m-t-xs"
                              :percentage="pctOrigemCadRobo"
                              color="blue" height="6px" style="border-radius: 5px; max-width: 100%"></u-progress>
                </div>
              </div>
              <div class="cursor-pointer m-t-lg" @click="gotoList({origem: 0})">
                <div class="flex justify-between">
                  <div class="col-grow-1">Formulário <small>{{stats.origemCadastral.formulario|number}}</small></div>
                  <div class="flex-zero text-right text-positive">{{ pctOrigemCadForm }}%</div>
                </div>
                <div class="bg-white">
                  <u-progress
                      class="m-t-xs"
                      :percentage="pctOrigemCadForm"
                      color="positive" height="6px" style="border-radius: 5px; max-width: 100%"></u-progress>
                </div>
              </div>
              <div class="cursor-pointer m-t-lg" @click="gotoList({origem: 2})">
                <div class="flex justify-between">
                  <div class="col-grow-1">API <small>{{stats.origemCadastral.api|number}}</small></div>
                  <div class="flex-zero text-right text-info">{{ pctOrigemCadApi }}%</div>
                </div>
                <div class="bg-white">
                  <u-progress
                      class="m-t-xs"
                      :percentage="pctOrigemCadApi"
                      color="info" height="6px" style="border-radius: 5px; max-width: 100%"></u-progress>
                </div>
              </div>
              <div class="m-t-lg text-grey-7">
                Os dados são baseados na <strong>entrada de bens</strong> no ERP.
              </div>
            </div>
          </div>
        </div>

        <div class="m-t resumo-qtd">
          <div class="cursor-pointer" @click="gotoList({ativo: 1, data1: '', data2: ''})">
            <div>Bens cadastrados ativos</div>
            <div><strong>{{ stats.origemCadastral.disponibilidade.ativos.total|number }}</strong> <span>R$ {{ stats.origemCadastral.disponibilidade.ativos.avaliacao|moeda }}</span></div>
          </div>
          <div class="cursor-pointer" @click="gotoList({analiseCadastral: 'disponivel', data1: '', data2: ''})">
            <div>Disponível (# e R$)</div>
            <div><strong>{{ stats.origemCadastral.disponibilidade.disponiveis.total|number }}</strong> <span>R$ {{ stats.origemCadastral.disponibilidade.disponiveis.avaliacao|moeda }}</span></div>
          </div>
          <div class="cursor-pointer" @click="gotoList({analiseCadastral: 'bloqueado', data1: '', data2: ''})">
            <div>Indisponível (# e R$)</div>
            <div><strong>{{ stats.origemCadastral.disponibilidade.indisponiveis.total|number }}</strong> <span>R$ {{ stats.origemCadastral.disponibilidade.indisponiveis.avaliacao|moeda }}</span></div>
          </div>
          <div>
            <u-btn @click="bemWindow(null)" icon="plus" icon-type="fa" label="Cadastrar bem" color="blue-4" class="full-width" no-caps />
          </div>
        </div>

        <div class="resumo-ativos hbox wrapper m-t-lg">
          <div class="flex justify-between m-b-lg">
            <div class="col-grow-1 font-16 text-grey-6">Resumo dos ativos</div>
            <div class="col-grow-1 text-right justify-end flex">
              <!-- DATAS -->
            </div>
          </div>
          <div class="resumo-graficos" style="cursor:pointer;">
            <div class="cursor-pointer" @click="r.codigoAnalise && gotoList({analiseCadastral: r.codigoAnalise})" v-for="(r, key) in stats.resumoAtivos" :key="'ra-' + key">
              <div class="title">{{ r.title }} <div><small class="font-11 font-thin">{{r.total}}</small></div></div>
              <div class="value">Mercado: <span>R$ {{r.mercado|moeda}}</span></div>
              <div class="value">Avaliação: <span>R$ {{r.avaliacao|moeda}}</span></div>
              <div class="value">Mínimo: <span>R$ {{r.minimo|moeda}}</span></div>
              <div class="m-t">
                <u-knob :value="r.title !== 'Total' ? pct(r.total, stats.resumoAtivos[stats.resumoAtivos.length - 1].total) : 100" readonly
                        :color="colors.resumoAtivos[key]"
                        line-width="4px"
                        track-color="grey-3">{{ Math.floor(r.title !== 'Total' ? pct(r.total, stats.resumoAtivos[stats.resumoAtivos.length - 1].total) : 100) }}%
                </u-knob>
              </div>
            </div>
          </div>
        </div>

        <div class="resumo-ativos hbox wrapper m-t m-b">
          <div class="flex justify-between m-b-lg">
            <div class="col-grow-1 font-16 text-grey-6">Resumo operacional</div>
            <div class="col-grow-1 text-right justify-end flex">
<!--              <select>
                <option>Mês atual</option>
              </select>-->
            </div>
          </div>
          <div class="resumo-graficos">
            <div class="cursor-pointer" @click="gotoList({evento: r.title})" v-for="(r, key) in stats.resumoOperacional" :key="'ro-' + key">
              <div class="title">{{ r.title }}</div>
              <div class="m-t">
                <u-knob :value="r.total" readonly
                        :color="colors.resumoOperacional[key]"
                        line-width="4px"
                        track-color="grey-3">{{ r.total|number }}
                </u-knob>
              </div>
            </div>
          </div>
        </div>

        <dash-ativos-financeiro :filtros="filtros" :stats="stats" />
      </div>
    </transition>
  </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
